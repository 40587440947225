<template>
  <div class="bbr-exercise--methods pt-10">
    <v-row>
      <v-col :md="8">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Directions</h2>

          <v-btn
            class="ml-auto px-8 primary--text"
            @click="addNewDirection = true"
            text
            depressed
          >
            <v-icon class="mr-3"> {{ icons.add }} </v-icon>
            Add New Direction
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12 ml-2"
            v-if="!hasChanges"
            :to="{
              name: 'form.recipe.preview',
              params: { id: $attrs.id },
            }"
            text
            depressed
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>

          <v-btn
            v-if="hasChanges"
            :loading="form.$busy"
            class="bg-primary-gradient primary ml-4 px-12"
            @click="saveChanges"
            depressed
          >
            Save Changes
          </v-btn>
        </div>

        <div class="methods-list mt-8">
          <div class="mb-7 clearfix" v-if="addNewDirection">
            <v-textarea
              label="Recipe Direction"
              v-model="method"
              rows="3"
              auto-grow
              clearable
              outlined
            ></v-textarea>

            <div class="float-right mt-n3">
              <v-btn class="mr-3 px-7" @click="cancel" text depressed>
                <span class="grey--text"> Cancel </span>
              </v-btn>
              <v-btn class="success px-8" @click="add" depressed>
                <span class="white--text" v-if="methodIndex >= 0">
                  Update Direction
                </span>
                <span class="white--text" v-else>
                  Add Direction
                </span>
              </v-btn>
            </div>
          </div>

          <hr v-if="addNewDirection && hasDirections" class="mt-10 mb-7" />

          <div class="methods-list--container pt-5">
            <ol v-if="hasDirections">
              <li v-for="(method, index) in methodsCopy" :key="index">
                <div class="d-flex align-center">
                  <span class="mr-10" @click="edit(index, method)">
                    {{ method }}
                  </span>
                  <v-spacer>
                    <v-divider />
                  </v-spacer>
                  <v-btn
                    class="ml-auto btn-remove"
                    @click="removeDirection(index)"
                    color="primary"
                    icon
                  >
                    <v-icon class="primary--text"> {{ icons.remove }} </v-icon>
                  </v-btn>
                </div>
              </li>
            </ol>

            <div
              v-else
              class="d-flex justify-center align-center text-center py-12"
            >
              <div>
                <img
                  class="pa-0"
                  width="400px"
                  src="@/assets/images/no-lists.png"
                  alt=""
                />
                <h1 class="display-1 grey--text no-workouts-list">
                  No Directions
                </h1>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmSave">
      <template v-slot:cancel>
        <v-btn color="grey" text @click.native="cancel"> No </v-btn>
      </template>
    </confirm-dialog>

    <v-snackbar v-model="snackbar.show" :timeout="5000" :right="true">
      {{ snackbar.message }}
      <v-btn color="primary" text @click="snackbar.show = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { mapState, mapActions } from 'vuex'
import { mdiPlus, mdiTrashCanOutline, mdiArrowRight } from '@mdi/js'
import { pick, clone, isEqual, isNull } from 'lodash'
import Form from '@/utils/form'

export default {
  name: 'RecipeDirectionsTab',

  components: {
    ConfirmDialog,
  },

  data() {
    return {
      food: {},
      methods: [],
      methodIndex: -1,
      method: null,
      methodsCopy: [],
      addNewDirection: false,
      icons: {
        add: mdiPlus,
        next: mdiArrowRight,
        remove: mdiTrashCanOutline,
      },
      snackbar: {
        show: false,
        message: '',
      },
      form: new Form({
        id: null,
        title: null,
        methods: [],
        raw: {
          food_id: null,
        },
      }),
    }
  },

  computed: {
    ...mapState({
      selectedData: (state) => state.recipe.selectedData,
    }),

    hasChanges() {
      return !isEqual(this.methodsCopy, this.methods)
    },

    hasDirections() {
      return this.methodsCopy ? !!this.methodsCopy.length : false
    },
  },

  methods: {
    ...mapActions({
      getSingleData: 'recipe/getSingleData',
      saveData: 'recipe/saveData',
    }),

    async fetchData(id) {
      this.loading = true
      await this.getSingleData(id)

      this.setSelectedData()

      this.loading = false
    },

    setSelectedData() {
      this.methods = this.selectedData.methods || []
      this.methodsCopy = clone(this.methods)
      this.fillForm()
    },

    fillForm() {
      let data = pick(this.selectedData, ['id', 'title', 'methods', 'raw'])
      if (isNull(data.raw) || data.raw === undefined) {
        data.raw = {
          food_id: null,
          title: null,
          serving_quantity: null,
          serving_unit: null,
        }
      } else {
        data.raw = {
          food_id: data.raw.id,
          title: data.raw.title,
          serving_quantity: data.raw.serving_quantity,
          serving_unit: data.raw.serving_unit,
        }
      }
      this.form = new Form(data)
    },

    cancel() {
      this.method = null
      this.addNewDirection = false
      this.methodIndex = -1

      this.methodsCopy = clone(this.methods)

      this.$refs.confirmSave.cancel()
    },

    add() {
      if (!this.method) return

      if (this.methodIndex >= 0) {
        // Update methods
        this.methodsCopy.splice(this.methodIndex, 1, this.method)
      } else {
        this.methodsCopy.push(this.method)
      }

      this.methodIndex = -1
      this.method = null
    },

    edit(index, method) {
      this.methodIndex = index
      this.method = method

      this.addNewDirection = true
    },

    removeDirection(index) {
      this.methodsCopy.splice(index, 1)
    },

    async saveChanges() {
      if (!this.hasChanges) return

      const self = this

      self.form.$busy = true
      self.addNewDirection = false
      self.methodIndex = -1
      self.form.methods = self.methodsCopy

      let data = await self.saveData(self.form.$data())

      self.form.$timeout(function() {
        self.snackbar.show = true
        self.snackbar.message = 'Changes succesfully saved!'

        self.form.$busy = false
        self.setSelectedData(data)
      })
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$attrs.id) {
        vm.fetchData(vm.$attrs.id)
      }
    })
  },

  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges) {
      const confirm = await this.$refs.confirmSave.open(
        'Save Changes',
        'Do you want to save changes?'
      )

      if (confirm) {
        await this.saveChanges()
        next()
      } else {
        next()
      }
    } else {
      next()
    }
  },
}
</script>

<style lang="scss">
.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.methods-list {
  &--container {
    width: 96%;
  }

  ol {
    &:hover {
      li:not(:hover) {
        opacity: 0.5;
      }
    }
  }

  li {
    padding-left: 20px;
    padding-bottom: 30px;
    opacity: 1;

    .btn-remove {
      visibility: hidden;
    }

    .spacer {
      .v-divider {
        visibility: hidden;
      }
    }

    &:hover {
      color: var(--v-primary-base);
      cursor: pointer;

      .btn-remove {
        visibility: visible;
      }

      .spacer {
        .v-divider {
          visibility: visible;
        }
      }
    }
  }
}
</style>
